// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assistcard-tsx": () => import("./../../../src/pages/assistcard.tsx" /* webpackChunkName: "component---src-pages-assistcard-tsx" */),
  "component---src-pages-buscar-tsx": () => import("./../../../src/pages/buscar.tsx" /* webpackChunkName: "component---src-pages-buscar-tsx" */),
  "component---src-pages-checkout-finish-tsx": () => import("./../../../src/pages/checkout-finish.tsx" /* webpackChunkName: "component---src-pages-checkout-finish-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-cotiza-segurodeviaje-tsx": () => import("./../../../src/pages/cotiza/segurodeviaje.tsx" /* webpackChunkName: "component---src-pages-cotiza-segurodeviaje-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preguntas-frecuentes-tsx": () => import("./../../../src/pages/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-tsx" */),
  "component---src-pages-segundo-paso-tsx": () => import("./../../../src/pages/segundo-paso.tsx" /* webpackChunkName: "component---src-pages-segundo-paso-tsx" */),
  "component---src-pages-servicio-telemedicina-tsx": () => import("./../../../src/pages/servicio-telemedicina.tsx" /* webpackChunkName: "component---src-pages-servicio-telemedicina-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */),
  "component---src-pages-sobre-nosotros-tsx": () => import("./../../../src/pages/sobre-nosotros.tsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-tsx" */),
  "component---src-pages-travel-insurance-tsx": () => import("./../../../src/pages/travel-insurance.tsx" /* webpackChunkName: "component---src-pages-travel-insurance-tsx" */)
}

