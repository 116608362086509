import React from 'react';
import { Provider } from 'react-redux';
// import { createStore as reduxCreateStore } from 'redux';
import {PersistGate} from 'redux-persist/integration/react'
// import {rootReducer} from '../reducers';
// const store = () => reduxstore(rootReducer);
// const persistor = persistStore(store);
import {createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import {rootReducer as reducers} from '../reducers';
import AsyncStorage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'compara-assist',
    storage: AsyncStorage
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
export default ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        {element}
    </PersistGate>
  </Provider>
);