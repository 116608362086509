import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useSelector } from "react-redux";
import quoteRequest from './QuoteRequest'
import plan from './Plan'

export const rootReducer =  combineReducers({
    quoteRequest,
    plan
});
export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;