import {Plan} from 'models'
interface Action {
    payload: Plan | null,
    type: string
}

function plan(state: Plan | null = null, action: Action): Plan | null {
	switch(action.type) {
		case 'SET_PLAN':
            return action.payload;
        case 'REMOVE_PLAN':
            return null;
        default:
        	return state;
	}
}

export default plan;